import "./report.css";
import { useEffect, useState } from "react";
import { ptBR } from "@mui/x-data-grid/locales";
import { userRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../components/context/CompanyContext";
import { formatPricing, formatTime24hourFormat } from "../../util/helper";
import dayjs from "dayjs";
import React from "react";
import Button from "@mui/material/Button";
import valor_medio from "../../icons/valor_medio.svg";
import { _convertFormatDate } from "../../util/helper";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import FormControl from "@mui/material/FormControl";
import { MultiSelect } from "react-multi-select-component";
import { Chart } from "react-google-charts";

export default function TreeMapReport() {
  const [stories, setStories] = useState([]);
  const [storeIds, setStoreIds] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orders, setOrders] = useState([
    ["Location", "Parent", "QuantityObj", "Percent", "Amount", "Quantity"],
  ]);

  const [dataIni, setDateIni] = useState();
  const [dataFim, setDateFim] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [readonly, setReadonly] = useState();

  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setReadonly("7");
    if (!currentUser) {
      nav(`../login`);
    }
    if (companyId > 0) {
      getStories();
      getOrders();
    }
    setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
    setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
  }, [companyId]);

  useEffect(() => {
    if (companyId > 0) {
      getOrders();
    }
  }, [dataIni, dataFim, storeIds]);

  useEffect(() => {
    // if (selected.length === stories.length) {
    //   return setStoreIds([]);
    // }
    setStoreIds(selected.map((item) => item.value));
  }, [selected]);

  const getStories = async () => {
    const res = await userRequest.get(`${companyId}/store/stories`);
    setStories(
      res.data.map((item) => ({
        label: item.store,
        value: item.storeId,
      }))
    );
  };
  const getOrders = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        stories: storeIds,
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/heapmap`,
          obj
        );
        setOrders(res.data);
      }
    } catch {}
  };

  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeEnd = (e) => {
    setDateFim(e.target.value);
  };
  const handleChange = (event) => {
    setReadonly(event.target.value);
    // eslint-disable-next-line default-case
    switch (event.target.value) {
      case "0":
        setDateIni(dayjs(new Date()).format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "7":
        setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "15":
        setDateIni(dayjs(new Date()).add(-15, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "30":
        setDateIni(dayjs(new Date()).add(-30, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
    }
  };

  const _voltarTopo = () => {
    window.scrollTo(0, 0);
  };
  const options = {
    // minColor: "#f00",
    // midColor: "#ddd",
    // maxColor: "#0d0",
    headerHeight: 15,
    fontColor: "black",
    showScale: true,
    generateTooltip: (row, size, value) => {
      //Location", "Parent", "QuantityObj", "Percent", "Amount", "Quantity
      const location = orders[row + 1][0];
      const qtdeOrder = orders[row + 1][2];
      const ticket = orders[row + 1][3];
      const amount = orders[row + 1][4];
      const qtde = orders[row + 1][5];

      return `
              <div style="background:white;border-color:grey;padding:10px;margin:5px; border-style:solid"> 
                <div>

                  <div>
                    <strong>${location} </strong>
                  </div>
                  <div>
                    <strong>Pedidos:</strong>${qtdeOrder}
                  </div>
                  <div>
                    <strong>Total:</strong>${formatPricing(amount)}
                  </div>
                  <div>
                    <strong>Ticket médio:</strong>${formatPricing(ticket)}
                  </div>
                  <div>
                    <strong>Itens:</strong>${qtde}
                  </div>                            
                </div>
              </div>`;
    },
  };

  // const data = [
  //   ["Location", "Parent", "Quantity", "Amount"],
  //   ["BR", null, 125, 0],
  //   ["SP", "BR", 45, 0],
  //   ["PR", "BR", 80, 0],
  //   ["São Bernardo", "SP", 15, 5000],
  //   ["São Paulo", "SP", 20, 24785],
  //   ["Diadema", "SP", 10, 4525],
  //   ["Curitiba", "PR", 50, 4525],
  //   ["Morretes", "PR", 30, 22],
  // ];
  return (
    <div className="div_home_Container">
      <table width="100%" className="tableSpaceTab">
        <tr>
          <td style={{ textAlign: "right" }}>
            <input
              type="radio"
              id="30"
              value="30"
              onChange={handleChange}
              checked={readonly === "30"}
            />
            <label htmlFor="30">30 dias</label>
            <input
              type="radio"
              id="15"
              value="15"
              onChange={handleChange}
              checked={readonly === "15"}
            />
            <label htmlFor="15">15 dias</label>
            <input
              type="radio"
              id="7"
              value="7"
              onChange={handleChange}
              checked={readonly === "7"}
            />
            <label htmlFor="7">7 dias</label>
            <input
              type="radio"
              id="0"
              value="0"
              onChange={handleChange}
              checked={readonly === "0"}
            />
            <label htmlFor="0">Hoje</label>
            <input
              type="radio"
              id="0"
              value="F"
              onChange={handleChange}
              checked={readonly === "F"}
            />
            <label htmlFor="0">Filtro</label>
          </td>
          <td style={{ textAlign: "right", width: "24%" }}>
            <label className="labelPeriodo">
              Filtrar:
              <input
                type="date"
                id="startDate"
                value={dataIni}
                name="trip-start"
                min="2023-01-01"
                onChange={handleChangeStart}
                disabled={readonly !== "F"}
              />
              <input
                type="date"
                id="endDate"
                value={dataFim}
                name="trip-start"
                min="2023-01-01"
                onChange={handleChangeEnd}
                disabled={readonly !== "F"}
              />
            </label>
          </td>

          <td style={{ textAlign: "right", width: "25%" }}>
            <FormControl sx={{ minWidth: 300 }} size="small">
              <MultiSelect
                overrideStrings={{
                  selectAll: "Todos",
                  search: "Buscar",
                  selectSomeItems: "Lojas",
                  allItemsAreSelected: "Todas lojas selecionadas.",
                  noOptions: "Nenhuma loja",
                }}
                options={stories}
                value={selected}
                onChange={setSelected}
                labelledBy="Lojas"
              />
            </FormControl>
          </td>
        </tr>
      </table>
      <table width="100%" className="tableSpaceTab2">
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tbody>
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={valor_medio} />
                      </td>
                      <td>
                        <tr className="labelTitle">Heatmap</tr>
                        <tr className="labelMsgData">
                          {readonly === "F"
                            ? "Exibindo dados do filtro de data"
                            : readonly === "0"
                            ? "Exibindo dados de hoje"
                            : "Exibindo dados dos últimos " +
                              readonly +
                              " dias"}
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    <div className="divRodape" onClick={getOrders}>
                      Atualizar
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <tr>
              <Chart
                chartType="TreeMap"
                width="100%"
                height="100%"
                data={orders}
                options={options}
              />
            </tr>
          </div>
        </tr>
      </table>
      <div className="divRodape" onClick={_voltarTopo}>
        voltar ao topo
      </div>
    </div>
  );
}
