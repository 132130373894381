import "../../global.css";
import "./store.css";
import dayjs from "dayjs";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR } from "@mui/x-data-grid/locales";
import { useEffect } from "react";
import { useState } from "react";
import { userRequest } from "../../requestMethods";
import IconButton from "@mui/material/IconButton";
import { formatTimeDDMMYYY } from "../../util/helper";
import { DeleteOutline } from "@mui/icons-material";
import { useCompany } from "../../components/context/CompanyContext";
import Swal from "sweetalert2";
import Loading from "../../components/loading/loading";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { titleSetRef } from "../../redux/titleRedux";
export default function StoreList() {
  const [stores, setStores] = useState([{}]);
  const { companyId } = useCompany();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const [uniqueDiscount, setUniqueDiscount] = useState(false);
  const [inputs, setInputs] = useState();
  const [date, setDate] = useState();
  const [dateNow, setDateNow] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(titleSetRef({ title: "Loja" }));
    if (companyId > 0) {
      getStores();
    }
  }, [companyId]);

  const getStores = async () => {
    try {
      const res = await userRequest.get(`${companyId}/store`);
      setStores(res.data);
    } catch {
      console.log();
    }
  };
  const addStore = async () => {
    let valida = false;
    if (inputs && inputs.store !== "") {
      valida = true;
    } else {
      Swal.fire("Atenção!", "O campo Loja deve ser preenchido.", "warning");
      valida = false;
      return;
    }

    if (valida) {
      const res = await userRequest.post(`${companyId}/store`, {
        store: inputs.store.toUpperCase(),
      });
      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Loja criada com sucesso!", "success");
        getStores();
      }
    }
  };
  const deleteStore = async (cp) => {
    const res = await userRequest.put(`${companyId}/usersRep/${cp}`, {
      deleted: new Date(),
      lastUserUpdate: currentUser.userId,
    });

    if (res.data > 0) {
      getStores();
    } else {
      Swal.fire("Atenção!", "Erro ao excluir desconto!", "error");
    }
  };
  const onButtonDeleteClick = async (e, row) => {
    if (currentUser.isAdmin) {
      Swal.fire({
        title: "Tem certeza que deseja excluir?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          deleteStore(row);
        }
      });
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir o representante!",
        "warning"
      );
    }
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const columns = [
    {
      field: "store",
      align: "left",
      headerName: "Store",
      width: 100,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "deleted",
      headerName: "Inativado",
      width: 150,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueFormatter: (value) => {
        if (value === undefined || value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "deletar",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (!params.row.deleted) {
          return (
            <IconButton
              onClick={(e) => onButtonDeleteClick(e, params.row.storeId)}
            >
              <DeleteOutline />
            </IconButton>
          );
        }
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
            <div>
              <table style={{ borderSpacing: "4px" }}>
                <tr style={{ padding: "0px" }}>
                  <td className="tdCustom">Loja</td>
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px", width: "350px" }}
                      type="text"
                      name="store"
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div style={{ padding: "0px" }}>
              <Button
                variant="outlined"
                color="secondary"
                className="custom_mui_action"
                startIcon={<SaveIcon />}
                onClick={addStore}
              >
                Adicionar Loja
              </Button>
            </div>
            <div className="div_custom_UpdateLeft">
              <div style={{ height: 500 }}>
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.storeId + row.store}
                  rows={stores}
                  disableSelectionOnClick
                  columns={columns}
                  pagination
                  autoPageSize
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
