import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import CustomerReport from "../reports/customerReport";
import OrderReport from "../reports/ordersReport";
import ProductReport from "../reports/productReport";
import PreSalesReport from "../reports/preSalesReport";
import PaymentsReport from "../reports/paymentsReport";
import TargetReport from "../reports/targetReport";
import Heatmap from "../reports/heatMapReport";
import { useDispatch, useSelector } from "react-redux";
import { titleSetRef } from "../../redux/titleRedux";
import "./tabDashboard.css";
import StockReport from "../reports/stockReport";
import TreeMapReport from "../reports/treeMapReport";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabDashboard() {
  const searchParams = new URLSearchParams(window.location.search);
  const tab = parseInt(searchParams.get("tab"));
  const [value, setValue] = useState(tab);
  const dispatch = useDispatch();
  useEffect(() => {
    setValue(tab);
    dispatch(titleSetRef({ title: "Dashboard" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      minWidth: 0,
      [theme.breakpoints.up("sm")]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#EE8253",
        opacity: 1,
      },
      "&.Mui-selected": {
        color: "#EE8253",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#d1eaff",
      },
    })
  );
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        id="box-header-dash"
      >
        <Tabs value={value} onChange={handleChange}>
          <AntTab label="Vendas" {...a11yProps(0)} />
          <AntTab label="Clientes" {...a11yProps(1)} />
          <AntTab label="Produtos" {...a11yProps(2)} />
          <AntTab label="Pré-vendas" {...a11yProps(3)} />
          <AntTab label="Pagamentos" {...a11yProps(4)} />
          <AntTab label="Estoque" {...a11yProps(5)} />
          <AntTab label="Metas campanhas" {...a11yProps(6)} />
          <AntTab label="Heatmap" {...a11yProps(7)} />
          {/* <AntTab label="Mapa Calor" {...a11yProps(5)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <OrderReport />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {<CustomerReport />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ProductReport />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <PreSalesReport />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <PaymentsReport />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <StockReport />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <TargetReport />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        <TreeMapReport />
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={5}>
        <Heatmap />
      </CustomTabPanel> */}
    </Box>
  );
}
