import "./report.css";
import { useEffect, useState } from "react";
import { ptBR } from "@mui/x-data-grid/locales";
import { userRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../components/context/CompanyContext";
import { formatTime24hourFormat } from "../../util/helper";
import dayjs from "dayjs";
import React from "react";
import Button from "@mui/material/Button";
import valor_medio from "../../icons/valor_medio.svg";
import { _convertFormatDate } from "../../util/helper";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { formatPricing } from "../../util/helper";
import FormControl from "@mui/material/FormControl";
import { MultiSelect } from "react-multi-select-component";
import { Chart } from "react-google-charts";

export default function TargetReport() {
  const [analitico, setAnalitico] = useState([]);
  const [consolidate, setConsolidate] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [campaigns, setCampaigns] = useState([]);
  const [selected, setSelected] = useState([]);
  const [compaignIds, setCompaignIds] = useState([]);
  const [vends, setVends] = useState([["Label", "Value"]]);

  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!currentUser) {
      nav(`../login`);
    }
    if (companyId > 0) {
      getAllCompaign();
    }
  }, [companyId]);

  useEffect(() => {
    setCompaignIds(selected.map((item) => item.value));
  }, [selected]);

  useEffect(() => {
    if (companyId > 0) {
      getConsolidade();
      getConsolidadeUser();
      if (compaignIds.length === 0) {
        setAnalitico([]);
      }
    }
  }, [compaignIds]);

  const getAllCompaign = async () => {
    const res = await userRequest.get(`${companyId}/campaign/active`);

    setCampaigns(
      res.data.map((item) => ({
        label: item.title,
        value: item.campaignId,
      }))
    );
  };
  const getAnaliticoexcelente = async () => {
    const res = await userRequest.post(
      `${companyId}/reportTargets/analiticTarget`,
      { compaignIds: compaignIds, filter: "Excelente" }
    );

    setAnalitico(res.data);
  };

  const getAnaliticoOtimo = async () => {
    const res = await userRequest.post(
      `${companyId}/reportTargets/analiticTarget`,
      { compaignIds: compaignIds, filter: "Otimo" }
    );

    setAnalitico(res.data);
  };

  const getAnaliticoBom = async () => {
    const res = await userRequest.post(
      `${companyId}/reportTargets/analiticTarget`,
      { compaignIds: compaignIds, filter: "Bom" }
    );

    setAnalitico(res.data);
  };
  const getAnaliticoRazoavel = async () => {
    const res = await userRequest.post(
      `${companyId}/reportTargets/analiticTarget`,
      { compaignIds: compaignIds, filter: "Razoavel" }
    );

    setAnalitico(res.data);
  };
  const getAnaliticoRuim = async () => {
    const res = await userRequest.post(
      `${companyId}/reportTargets/analiticTarget`,
      { compaignIds: compaignIds, filter: "Ruim" }
    );

    setAnalitico(res.data);
  };
  const getConsolidade = async () => {
    try {
      const res = await userRequest.post(
        `${companyId}/reportTargets/consolidateTarget`,
        { compaignIds: compaignIds, filter: "Todos" }
      );

      setConsolidate(res.data);
    } catch {}
  };
  const getConsolidadeUser = async () => {
    try {
      const res = await userRequest.post(
        `${companyId}/reportTargets/consolidateTargetUser`,
        { compaignIds: compaignIds, filter: "Todos" }
      );

      setVends(res.data);
    } catch {}
  };
  const options = {
    width: "100%",
    height: 200,
    greenFrom: 90,
    greenTo: 100,
    yellowFrom: 70,
    yellowTo: 90,
    minorTicks: 5,
    redFrom: 0,
    redTo: 70,
  };
  const data = [
    ["Label", "Value"],
    ["DANIEL", 30],
    ["FABIO", 90],
  ];
  const columns = [
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "owner_cpf",
      headerName: "CPF proprietário",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "rzSocial",
      headerName: "Razão Social",
      width: 200,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "quantityOrders",
      headerName: "Qtde Pedidos",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "quantity",
      headerName: "Qtde Itens",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "amount",
      headerName: "Valor",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  const _voltarTopo = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="div_home_Container">
      <table width="100%" className="tableSpaceTab">
        <tr align="right">
          <FormControl sx={{ minWidth: 300 }} size="small">
            <MultiSelect
              overrideStrings={{
                selectAll: "Todos",
                search: "Buscar",
                selectSomeItems: "Campanhas",
                allItemsAreSelected: "Todas campanhas selecionadas.",
                noOptions: "Nehuma campanha",
              }}
              options={campaigns}
              value={selected}
              onChange={setSelected}
              labelledBy="Campanhas"
            />
          </FormControl>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={valor_medio} />
                    </td>
                    <td>
                      <tr className="labelTitle">Metas</tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>
            <table className="table_painel">
              <tr>
                <td width="20%" className="status_painelw">
                  Excelente(
                  {consolidate && consolidate.countExcelente
                    ? consolidate.countExcelente
                    : 0}
                  )<p className="labelMsgDataAtivosw">(Acima 100%)</p>
                </td>
                <td width="20%" className="status_painelw">
                  Ótimo(
                  {consolidate && consolidate.countOtimo
                    ? consolidate.countOtimo
                    : 0}
                  )<p className="labelMsgDataAtivosw">(95% a 100%)</p>
                </td>
                <td width="20%" className="status_painelw">
                  Bom(
                  {consolidate && consolidate.countBom
                    ? consolidate.countBom
                    : 0}
                  )<p className="labelMsgDataAtivosw">(80% a 95%)</p>
                </td>
                <td width="20%" className="status_painelw">
                  Razoável(
                  {consolidate && consolidate.countRazoavel
                    ? consolidate.countRazoavel
                    : 0}
                  )<p className="labelMsgDataAtivosw">(70% a 80%)</p>
                </td>
                <td width="20%" className="status_painelw">
                  Ruim(
                  {consolidate && consolidate.countRuim
                    ? consolidate.countRuim
                    : 0}
                  )<p className="labelMsgDataAtivosw">(Abaixo 70%)</p>
                </td>
              </tr>
              <tr>
                <td
                  width="20%"
                  className="labelValue_painel_black_vlr"
                  onClick={getAnaliticoexcelente}
                >
                  {consolidate && consolidate.sumExcelente
                    ? formatPricing(consolidate.sumExcelente)
                    : "R$ 0,00"}
                </td>
                <td
                  width="20%"
                  className="labelValue_painel_black_vlr"
                  onClick={getAnaliticoOtimo}
                >
                  {consolidate && consolidate.sumOtimo
                    ? formatPricing(consolidate.sumOtimo)
                    : "R$ 0,00"}
                </td>
                <td
                  width="20%"
                  className="labelValue_painel_black_vlr"
                  onClick={getAnaliticoBom}
                >
                  {consolidate && consolidate.sumBom
                    ? formatPricing(consolidate.sumBom)
                    : "R$ 0,00"}
                </td>
                <td
                  width="20%"
                  className="labelValue_painel_black_vlr"
                  onClick={getAnaliticoRazoavel}
                >
                  {consolidate && consolidate.sumRazoavel
                    ? formatPricing(consolidate.sumRazoavel)
                    : "R$ 0,00"}
                </td>
                <td
                  width="20%"
                  className="labelValue_painel_black_vlr"
                  onClick={getAnaliticoRuim}
                >
                  {consolidate && consolidate.sumtRuim
                    ? formatPricing(consolidate.sumtRuim)
                    : "R$ 0,00"}
                </td>
              </tr>
            </table>
          </div>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={valor_medio} />
                    </td>
                    <td>
                      <tr className="labelTitle">Analítico metas</tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>
            <div style={{ height: 350, width: "100%" }}>
              <DataGridPremium
                sx={{
                  fontFamily: "Manrope",
                  fontWeight: "500",
                  border: "0px",
                }}
                rowHeight={45}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  panel: {
                    sx: {
                      top: "15px !important",
                      left: "50px !important",
                      position: "fixed !important",
                    },
                  },
                }}
                getRowId={(row) => row.name}
                rows={analitico}
                disableSelectionOnClick
                columns={columns}
                pagination
                autoPageSize
              />
            </div>
          </div>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tbody>
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={valor_medio} />
                      </td>
                      <td>
                        <tr className="labelTitle">Metas vendedores</tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    <div className="divRodape" onClick={getConsolidadeUser}>
                      Atualizar
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <tr>
              <Chart chartType="Gauge" data={vends} options={options} />
            </tr>
          </div>
        </tr>
      </table>
      <div className="divRodape" onClick={_voltarTopo}>
        voltar ao topo
      </div>
    </div>
  );
}
