import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import "../../global.css";
import { userRequest } from "../../requestMethods";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Modal from "@mui/material/Modal";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import Container from "@mui/material/Container";
import { formatPricing, formatPricingDatabase } from "../../util/helper";
const style = {
  background: "white",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  padding: "60px",
  borderRadius: "15px",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

export default function UserRep() {
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [user, setUser] = useState({});
  const [inputs, setInputs] = useState({});
  const [cargo, setCargo] = useState("");
  const [newManaged, setNewManaged] = useState();
  const [admin, setAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [existUsername, setExistUsername] = useState();
  const { companyId } = useCompany();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState();
  const [stories, setStories] = useState([]);
  const [store, setStore] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const nav = useNavigate();

  useEffect(() => {
    if (companyId > 0) {
      if (id !== "new") {
        getUser();
        getUsers();
      }
    }
  }, [id, companyId]);

  const getUser = async () => {
    try {
      const res = await userRequest.get(`${companyId}/usersRep/find/${id}`);

      setUser(res.data);
    } catch {
      console.log();
    }
  };

  const getUsername = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/usersRep/username/${inputs.username}`
      );

      if (Object.keys(res.data).length > 0) {
        setExistUsername(true);
      } else {
        setExistUsername(false);
      }
    } catch {
      console.log();
    }
  };

  const validate = () => {
    if (inputs.username === "" || inputs.username === undefined) {
      Swal.fire("Atenção!", "O campo Username deve ser preenchido.", "warning");
      return false;
    }

    if (existUsername) {
      Swal.fire("Atenção!", "Username existente.", "warning");
      return false;
    }
    if (inputs.name === "" || inputs.name === undefined) {
      Swal.fire("Atenção!", "O campo Nome deve ser preenchido.", "warning");

      return false;
    }
    if (inputs.cpf === "" || inputs.cpf === undefined) {
      Swal.fire("Atenção!", "O campo CPF deve ser preenchido.", "warning");

      return false;
    }
    if (inputs.email === "" || inputs.email === undefined) {
      Swal.fire("Atenção!", "O campo Email deve ser preenchido.", "warning");

      return false;
    }

    if (inputs.contact === "" || inputs.contact === undefined) {
      Swal.fire("Atenção!", "O campo Telefone deve ser preenchido.", "warning");

      return false;
    }
    if (inputs.address === "" || inputs.address === undefined) {
      Swal.fire("Atenção!", "O campo Endereço deve ser preenchido.", "warning");

      return false;
    }

    return true;
  };
  const updateClick = async (event) => {
    setIsLoading(true);
    if (id !== "new") {
      UpdateUser();
    } else {
      if (validate()) {
        InserirUser();
      }
    }
    setIsLoading(false);
  };
  const UpdateUser = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/usersRep/${id}`, {
        name: inputs.name,
        email: inputs.email,
        contact: inputs.contact,
        username: inputs.username,
        password: inputs.password,
        cpf: inputs.cpf,
        address: inputs.address,
        lastUserUpdate: currentUser.userId,
      });

      if (Object.keys(res.data).length > 0) {
        Swal.fire(
          "Parabéns!",
          "Representante alterado com sucesso!",
          "success"
        );
        nav(`../users`);
      } else {
        Swal.fire("Atenção!", "Erro ao alterar representante!", "error");
      }
    } catch (error) {}
  };
  const InserirUser = async () => {
    try {
      const res = await userRequest.post(`${companyId}/usersRep/registerRep`, [
        {
          name: inputs.name,
          email: inputs.email,
          contact: inputs.contact,
          username: inputs.username,
          password: inputs.password,
          cpf: inputs.cpf,
          address: inputs.address,
          lastUserUpdate: currentUser.userId,
        },
      ]);

      if (Object.keys(res.data).length > 0) {
        Swal.fire(
          "Parabéns!",
          "Representante inserido com sucesso!",
          "success"
        );
        nav(-1);
      } else {
        Swal.fire("Atenção!", "Erro ao inserir representante!", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUsers = async () => {
    try {
      let url = `${companyId}/usersRep`;

      const res = await userRequest.get(url);

      setUsers(res.data);
    } catch (error) {
      console.log(error.code);
    }
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleBlur = (e) => {
    getUsername();
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Form">
          {id !== "new" && user ? (
            <span className="div_custom_UpdateTitle">Editar Representante</span>
          ) : (
            <span className="div_custom_UpdateTitle">Novo Representante</span>
          )}
          <div className="div_custom_UpdateLeft">
            <div className="div_custom_UpdateItem">
              <label>Username</label>
              <input
                type="text"
                className="div_custom_UpdateInput"
                name="username"
                defaultValue={id !== "new" && user ? user.username || "" : ""}
                onChange={handleChange}
                maxLength="20"
                onBlur={handleBlur}
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Senha</label>
              <input
                type="text"
                name="password"
                defaultValue={id !== "new" && user ? "" || "" : ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
                maxLength="20"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Nome</label>
              <input
                type="text"
                name="name"
                defaultValue={id !== "new" && user ? user.name || "" : ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>CPF</label>
              <input
                type="number"
                name="cpf"
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user ? user.cpf || "" : ""}
                onChange={handleChange}
                maxLength="11"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Email</label>
              <input
                type="email"
                name="email"
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user ? user.email || "" : ""}
                onChange={handleChange}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Telefone</label>
              <input
                type="number"
                name="contact"
                onChange={handleChange}
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user ? user.contact || "" : ""}
                maxLength="12"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Endereço</label>
              <input
                type="text"
                name="address"
                onChange={handleChange}
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user ? user.address || "" : ""}
                maxLength="200"
              />
            </div>
          </div>
          <div>
            {id !== "new" && user ? (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={updateClick}
              >
                Alterar
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={updateClick}
              >
                Cadastrar
              </Button>
            )}
            <Button
              variant="outlined"
              color="secondary"
              className="custom_add_back"
              onClick={() => nav(-1)}
            >
              Voltar
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
}
